/* .RichEditor-root {
  
} */

.RichEditor-editor {
  cursor: text;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.02em;
  display: inline;
  font-weight: 400;
  max-width: 100%;
}

.RichEditor-editor h3 {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 19px;
  font-weight: 555;
  margin-bottom: 5px;
}

.RichEditor-editor div {
  margin-bottom: 10px;
}

.RichEditor-editor a {
  display: inline-block;
  margin-left: -41px;
  text-decoration: none;
  color: #2196f3;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  padding: 15px;
}

.RichEditor-editor .public-DraftStyleDefault-block {
  margin-bottom: 5px;
}

pre {
  white-space: normal;
  background-color: #efefef;
  border-radius: 15px;
  margin-top: 4px;
  margin-bottom: 10px;
}

.RichEditor-editor .RichEditor-code-block {
  color: #52606eff;
  line-height: 20px;
}

.RichEditor-editor .RichEditor-center {
  text-align-last: center;
  text-align-last: -moz-center;
  text-align-last: -webkit-center;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  display: flex;
  justify-content: center;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  cursor: pointer;
  margin-right: 7px;
  margin-top: 7px;
  padding: 7px 14px 7px 14px;
  display: inline-block;

  background-color: rgb(255, 255, 255);
  border: 1px solid #d8d8d8;
  -webkit-box-shadow: 0 0 6px #9d9d9d53;
  -moz-box-shadow: 0 0 6px #9d9d9d53;
  box-shadow: 0 0 6px #9d9d9d53;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  color: rgb(99, 99, 99);
  font-size: 9px;
}

.RichEditor-activeButton {
  color: white;
  background-color: #7abcff;
}

.textImage {
  display: block;
  margin: auto;
  max-width: 90%;
  max-height: 70vh;
  text-align-last: center;
  text-align-last: -moz-center;
  text-align-last: -webkit-center;
}